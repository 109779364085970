<template>
  <section>
    <v-container class="mainBox container-custom">
      <v-row class="marginMinus">
        <v-col md="4" cols="12" class="px-0 py-0 d-flex mobile-screen">
          <ul class="cateogyIcon" v-if="!loading">
            <li
              @click="filterPage(data.name)"
              v-for="(data, index) in categories"
              :key="index + 'category'"
            >
              <div class="catBox cursorIcon">
                <img :src="data.image" alt="" />
                {{ data.name == "4 Wheeler" ? "Car" : data.name }}
              </div>
            </li>
          </ul>
          <ul class="cateogyIcon" v-if="loading">
            <li v-for="n in 4" :key="n + 'category'">
              <LoadingSkeleton :image="2" />
            </li>
          </ul>
        </v-col>
        <v-col md="8" cols="12" class="px-0 py-0 mobile-screen">
          <Slider :images="banners" v-if="!loading" class="bannerSlider" />
          <LoadingSkeleton :image="4" v-if="loading" />
        </v-col>
      </v-row>
    </v-container>
    <section class="backG">
      <v-container class="container-custom">
        <v-row>
          <v-col cols="12">
            <FilterHome />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-15">
      <v-container class="container-custom">
        <v-row>
          <v-col cols="12">
            <FeaturedBox />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="maringTB mobile-screen">
      <v-row>
        <v-col class="relBox" md="6" cols="12">
          <img
            src="@/assets/images/backgroundAdvertisement.png"
            alt=""
            class="backImage"
          />
          <div class="content">
            <h1>Are you looking for a Vehicle?</h1>
            <p>
              Find your dream Vehicle in our webiste and ask us for a quote on
              it
            </p>
            <router-link to="/search">
              <v-btn class="blueBtn" large
                >Search <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </router-link>
          </div>
        </v-col>
        <v-col class="relBox" md="6" cols="12">
          <div class="content">
            <h1>Loan EMI Calculator</h1>
            <p>
              It is very easy to calculate the EMI for your vehicle loan. You
              will get EMI as soon as you enter the required loan amount and the
              interest rate.
            </p>
            <router-link to="/emicalculator">
              <v-btn class="whiteBtn" large
                >Calculate <v-icon> mdi-calculator </v-icon>
              </v-btn>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="my-15">
      <v-container class="container-custom">
        <v-row>
          <v-col cols="12">
            <FeaturedBox />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-15" v-if="false">
      <v-container class="container-custom">
        <v-row>
          <v-col cols="12">
            <ProductWithCategory />
          </v-col>
        </v-row>

        <v-row class="py-10">
          <v-col cols="12">
            <ProductWithCategory />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <ProductWithCategory />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="advertiesmentSection mobile-screen" v-if="false">
      <img src="@/assets/images/backgroundAdvertisement.png" alt="" />
      <h2>We Make Finding The Right Vehicle Simple</h2>
      <p>
        We have a wide range of vehicles for you to choose from. We have
        vehicles for every budget and every need. We have vehicles for every
        budget and every need.
      </p>
    </section>
    <section class="advertiesmentBoxes" v-if="false">
      <v-container class="container-custom">
        <v-row>
          <v-col
            md="6"
            v-for="(data, index) in advertisementsMiddle"
            :key="index + 'middle'"
          >
            <img :src="data.imageWeb" alt="" />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="text-left viewMore mt-5">
            <h2 class="text-left">Blogs</h2>
          </v-col>
        </v-row>
        <BlogHome />
        <v-row>
          <v-col md="12" class="text-center viewMore">
            <router-link to="/blogs"
              ><v-btn large
                >View All <v-icon> mdi-chevron-right </v-icon
                ><v-icon> mdi-chevron-right </v-icon></v-btn
              ></router-link
            >
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="gradientBack mobile-screen">
      <v-container class="container-custom">
        <v-row class="backBG">
          <v-col md="4" cols="12">
            <img src="@/assets/images/phone.png" alt="" />
          </v-col>
          <v-col class="leftSection">
            <h2>Shop cars, whether you’re on the lot oro no the go</h2>
            <p>
              Download the app and get the cars you want, wherever you are.
            </p>
            <ul class="downloadBtn">
              <li>
                <img src="@/assets/images/playstore.png" alt="" />
              </li>
              <li>
                <img src="@/assets/images/appstore.png" alt="" />
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import Slider from "@/components/user/Slider";
import FeaturedBox from "@/components/user/FeaturedBox";
import BlogHome from "@/components/user/BlogHome";
import ProductWithCategory from "@/components/user/ProductWithCategory";
import FilterHome from "@/components/user/FilterHome";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  data: () => ({
    snackbar: false,
    message: "",
    categories: [],

    slickOptions: {
      slidesToShow: 3,
      dots: false,
      arrows: false,
      fade: false,
    },
    banners: [],
    advertisementsMiddle: [],
    loading: true,
  }),
  methods: {
    ...mapActions(["getCategoryHomeList", "getFrontendAdvertisements"]),
    filterPage(data) {
      this.$router.push(`/search?category=${data}`);
    },
  },
  components: {
    Slider,
    BlogHome,
    ProductWithCategory,
    FeaturedBox,
    FilterHome,
    LoadingSkeleton,
  },
  computed: {
    ...mapGetters(["allLogin", "allCategoryList", "allAdvertisements"]),
  },
  async created() {
    if (this.allLogin != undefined) {
      this.snackbar = true;
      this.message = `Welcome ${this.allLogin.user.fullname}`;
    }
    await this.getFrontendAdvertisements({ adsFor: "top", limit: 10, page: 1 }),
      (this.banners = this.allAdvertisements.results);

    await this.getFrontendAdvertisements({
      adsFor: "middle",
      limit: 2,
      page: 1,
    }),
      (this.advertisementsMiddle = this.allAdvertisements.results);

    await this.getCategoryHomeList();
    this.categories = this.allCategoryList.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.marginMinus {
  margin-bottom: -25px;
}
.mainBox {
  overflow: hidden;
}
A {
  text-decoration: none;
}
.cateogyIcon {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-left: 0;
  padding-left: 0;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-top: -13px;
  li {
    padding: 5px 5px;
    width: 100%;
    border-radius: 10px;
    width: 50%;
    list-style: none;
    .catBox {
      height: 180px;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 2px solid #cdcccc;
      border-radius: 10px;
      img {
        width: 80px;
      }
    }
  }
}
.backG {
  background: #f9f9f9;
  padding: 30px 0;
  .v-btn {
    margin-top: 20px;
  }
}
img {
  width: 100%;
}

h2 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
}
.advertiesmentSection {
  height: 550px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    position: absolute;
  }
  h2 {
    text-align: center;
    margin-bottom: 0;
    z-index: 9;
    color: #fff;
    width: 500px;
    margin-bottom: 20px;
    font-size: 50px;
  }
  p {
    text-align: center;
    z-index: 9;
    position: relative;
    color: #fff;
    width: 500px;
    font-size: 18px;
    line-height: 32px;
  }
}
.advertiesmentBoxes {
  position: relative;
  margin-top: -120px;
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}

.relBox {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .content {
    position: relative;
    z-index: 99;
    padding: 0 90px;
    h1 {
      font-size: 40px;
      color: #fff;
      margin-bottom: 15px;
    }
    p {
      font-size: 20px;
      width: 60%;
      color: #fff;
      margin-bottom: 30px;
    }
  }
  position: relative;
  overflow: hidden;
  height: 450px;
  background: #0878d4;
  .backImage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.maringTB {
  margin-top: 30px;
  margin-bottom: 90px;
  .v-btn {
    min-height: 60px;
    width: 180px;
  }
}
.blueBtn {
  background: #0878d4 !important;
  font-size: 16px;
  .v-icon {
    font-size: 20px;
    margin-left: 10px;
  }
}
.whiteBtn {
  background: #fff !important;
  color: #0878d4 !important;
  font-size: 16px;
  .v-icon {
    font-size: 20px;
    margin-left: 10px;
  }
}
.downloadBtn {
  list-style: none;
  display: flex;
  padding-left: 0;
  height: 50px;
  img {
    width: 180px;
    height: 60px;
  }
  li {
    margin-right: 10px;
    img {
      margin-top: 10px;
    }
  }
}
.leftSection {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: left;
  padding-left: 30px;
  h2 {
    text-align: left;
    margin-bottom: 10px;
    line-height: 60px;
    font-size: 50px;
    color: #fff;
  }
  p {
    margin-bottom: 20px;
    color: #fff;
  }
}
.backBG {
  background: #0878d4;
  overflow: hidden;
  padding: 0 40px;
  img {
    margin-top: 80px;
    margin-bottom: -20px;
    width: 80%;
  }
}
.gradientBack {
  background: linear-gradient(to top, #f4f4f4 50%, white 50%);
  margin-top: 50px;
}
.v-btn {
  min-height: 60px;
  width: 180px;
  color: #fff;
}
.mobile-screen {
  @media only screen and (max-width: 991px) {
    .cateogyIcon {
      padding: 30px 10px 15px;
    }
    .relBox {
      .content {
        padding: 0 20px;
      }
    }
  }
}
.gradientBack.mobile-screen {
  @media only screen and (max-width: 991px) {
    .leftSection {
      padding: 20px 10px 80px;
    }
  }
}
</style>