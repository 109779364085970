<template>
  <section>
    <div>
      <h2>Featured Vehicle</h2>
      <ul class="brandFilter">
        <!-- <li>EV Bike</li> -->
        <!-- <li>EV Scooter</li>
        <li>EV Car</li>
        <li>EV Bus</li> -->
      </ul>
      <v-row v-if="loading">
        <v-col v-for="n in 4" :key="n" md="3" cols="6">
          <LoadingSkeleton :image="2" :title="2" :text="6" />
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col
          md="3"
          v-for="(data, index) in products"
          :key="index + 'product'"
        >
          <ProductCard :item="data" />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" class="text-center mt-5">
          <v-btn @click="filterPage" large
            >View All <v-icon> mdi-chevron-double-right </v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Featured",
  components: {
    ProductCard,
    LoadingSkeleton
  },
  data: () => ({
    limit: 12,
    page: 1,
    productType: "inquiry",
    loading: true,
    products: undefined,
  }),
  methods: {
    ...mapActions(["getFrotendProducts"]),
    filterPage() {
      this.$router.push("/search");
    },
  },
  computed: {
    ...mapGetters(["allProducts"]),
  },
  async created() {
    await this.getFrotendProducts({
      limit: this.limit,
      page: this.page,
      productType: this.productType,
    });
    this.products = this.allProducts.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
}
.brandFilter {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 40px;
  li {
    padding: 0 20px;
    list-style: none;
  }
}
.v-btn {
  background: #0878d4 !important;
  min-height: 50px;
  width: 200px;
  color:#fff;
}

</style>