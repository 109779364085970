<template>
  <section>
    <div class="productCardTop">
      <img
        src="https://png.pngtree.com/png-clipart/20201208/original/pngtree-luxury-auto-car-service-company-logo-concept-stock-illustration-png-image_5536092.jpg"
        alt=""
      />
       <v-btn class="">Battery</v-btn>
    </div>
  
  </section>
</template>

<script>
export default {
  name: "CategoryBox",
};
</script>

<style lang="scss" scoped>
.borderBox{
    border-bottom: 1px solid #EDEDED;
    display: block;
}
img {
  width: 100%;
   object-fit: cover;
  height: 385px;
  border-radius: 8px;
}
.productCardBody {
  padding: 15px;
  border: 2px solid #EDEDED;
  h2 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  hr{
      border-bottom: #fff;
  }
  .footer {
    list-style: none;
    padding-top: 10px;
    display: flex;
    padding-left: 0;
    font-size: 14px;
    li{
        margin-right: 10px;
    }
  }
}

.productCardTop {
  position: relative;
  height: 375px;
  .heart {
    position: absolute;
    right: 15px;
    top: 15px;
    color:#000;
    padding: 3px;
    border-radius: 50%;
    background: #fff;
  }
}
.v-btn{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff!important;
  color:#000!important;
  min-height: 50px;
  width: 150px;
  margin-bottom: 10px;
}
</style>