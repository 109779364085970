<template>
  <section>
    <v-row v-if="loading">
      <v-col md="6">
        <LoadingSkeleton :image="2" :title="2" :text="6" />
      </v-col>
      <v-col md="6">
        <LoadingSkeleton :image="2" :title="2" :text="6" />
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col md="6">
        <BlogBox
          :hr="false"
          v-for="(data, index) in blogs.slice(0, 1)"
          :key="index + 'blog'"
          :item="data"
        />
      </v-col>
      <v-col md="6">
        <BlogBox
          :hr="true"
          v-for="(data, index) in blogs.slice(1, 3)"
          :key="index + 'blogHr'"
          :item="data"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BlogBox from "@/components/BlogBox";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BlogHome",
  data: () => ({
    loading: true,
    blogs: [],
    limit: 3,
    page: 1,
  }),
  methods: {
    ...mapActions(["getFrontendBlogs"]),
  },
  computed: {
    ...mapGetters(["allBlogs"]),
  },
  components: {
    BlogBox,
    LoadingSkeleton
  },
  async created() {
    await this.getFrontendBlogs({ limit: this.limit, page: this.page });
    this.blogs = this.allBlogs.results;
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
</style>