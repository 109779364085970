<template>
  <div>
    <v-row>
      <v-col md="3">
        <CategoryBox @click="filterPage" />
      </v-col>
      <v-col md="9">
        <v-row v-if="loading">
          <v-col v-for="n in 3" :key="n" md="4">
            <LoadingSkeleton :image="2" :title="2" :text="6" />
          </v-col>
        </v-row>
        <ProductSlider v-if="!loading" :items="products" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductSlider from "@/components/ProductSlider";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import CategoryBox from "@/components/CategoryBox";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductSalesBox",
  components: {
    ProductSlider,
    CategoryBox,
    LoadingSkeleton,
  },
  data: () => ({
    limit: 10,
    page: 1,
    productType: "product",
    loading: true,
    products: undefined,
  }),
  methods: {
    ...mapActions(["getFrotendProducts"]),
    filterPage() {
      this.$router.push("/search");
    },
  },
  computed: {
    ...mapGetters(["allProducts"]),
  },
  async created() {
    await this.getFrotendProducts({
      limit: this.limit,
      page: this.page,
      productType: this.productType,
    });
    this.products = this.allProducts.results;
    this.loading = false;
  },
};
</script>

