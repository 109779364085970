<template>
  <section>
    <div>
      <h2>Browser Vehicle</h2>
      <ul class="brandFilter">
        <li :class="{ active: tab == 'brand' }" @click="tabChange('brand')">
          Brand
        </li>
        <li
          :class="{ active: tab == 'bodyType' }"
          @click="tabChange('bodyType')"
        >
          Body Type
        </li>
        <li :class="{ active: tab == 'year' }" @click="tabChange('year')">
          Year
        </li>
      </ul>
      <div v-if="tab == 'brand'">
        <v-row v-if="loading">
          <v-col md="3" cols="6" class="d-flex justify-center" v-for="n in 4" :key="n+'homeproduct'">
            <div class="boxLoading">
              <LoadingSkeleton :image="1" :title="2" />
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col md="3" v-for="(data, index) in brands.slice(0,8)" :key="index + 'brand'">
            <div class="companyLogo" @click="filterPage(`?brand=${data.brandName}`)">
              <img :src="data.image" alt=""/>
              <div class="titleBox">{{ data.brandName }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="text-center mt-5">
            <v-btn large @click="brandPage"
              >View All <v-icon> mdi-chevron-double-right </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div v-if="tab == 'bodyType'">
        <v-row>
          <v-col
            md="3"
            v-for="(data, index) in bodyTypes"
            :key="index + 'bodyType'"
          >
            <div class="companyLogo" @click="filterPage(`?bodyType=${data.bodyTypeName}`)">
              <img :src="data.image" alt="" />

              <div class="titleBox">{{ data.bodyTypeName }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="text-center mt-5">
            <v-btn large @click="bodyTypePage"
              >View All <v-icon> mdi-chevron-double-right </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div v-if="tab == 'year'">
        <v-row>
          <v-col md="3" v-for="(data, index) in years" :key="index + 'year'">
            <div class="companyLogo yearBox" @click="filterPage(`?year=${data.yearName}`)">
              <div class="titleBox">{{ data.yearName }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="text-center mt-5">
            <v-btn large @click="modelPage"
              >View All <v-icon> mdi-chevron-double-right </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingSkeleton from "@/components/LoadingSkeleton";
export default {
  name: "Featured",
  data: () => ({
    limit: 8,
    page: 1,
    productType: "inquiry",
    loading: true,
    brands: undefined,
    years: undefined,
    bodyTypes: undefined,
    tab: "brand",
  }),
  components: {
    LoadingSkeleton,
  },
  methods: {
    ...mapActions([
      "getBrandFrontend",
      "getYearFrontend",
      "getBodyTypeFrontend",
    ]),
    filterPage(data) {
      this.$router.push(`/search/${data}`);
    },
    brandPage() {
      this.$router.push("/brands");
    },
    bodyTypePage() {
      this.$router.push("/bodytypes");
    },
    modelPage() {
      this.$router.push("/years");
    },
    tabChange(data) {
      this.tab = data;
    },
  },
  computed: {
    ...mapGetters(["allBodyTypes", "allBrands", "allYears"]),
  },

  async created() {
    await this.getBrandFrontend({
      limit: this.limit,
      page: this.page,
    });
    await this.getYearFrontend({
      limit: this.limit,
      page: this.page,
    });
    await this.getBodyTypeFrontend({
      limit: this.limit,
      page: this.page,
    });
    this.years = this.allYears.results;
    this.brands = this.allBrands.results;
    this.bodyTypes = this.allBodyTypes.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
}
.brandFilter {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 40px;
  li {
    padding: 0 20px;
    list-style: none;
    cursor: pointer;
  }
}
.titleBox {
  text-transform: capitalize;
  margin-top: 5px;
}
.companyLogo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 10px;
  }
}
.v-btn {
  background: #0878d4 !important;
  min-height: 50px;
  width: 200px;
}
.boxLoading {
  width: 150px;
  overflow: hidden;
}
.yearBox{
   font-size: 20px;
  font-weight: 700;

  padding: 20px 15px;
  border-radius: 10px;
}
.active {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 30px;
    background: #0878d4;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.v-btn {
  color: #fff;
}
</style>