<template>
  <section>
    <div class="blogBox" :class="{ hrBox: hr  }">
      <div class="blogImage" v-if="item.images.length > 0">
        <img :src="item.images[0].media" alt="" />
      </div>

      <div class="blogContent">
        <h3>
          <router-link :to="`/blog/${item.slug}`">{{
            item.blogTitle
          }}</router-link>
        </h3>
        <!-- <div v-html="item.description.slice(0, 50)"></div> -->
        <ul class="blogFooter">
          <li class="textCapitalize">
            <v-icon> mdi-account-outline </v-icon>
            By: {{ item.postedBy }}
          </li>
          <li>
            <v-icon> mdi-calendar </v-icon>
            {{ item.createdAt.slice(0, 10) }}
          </li>
          <li>Category : {{ item.category }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlogBox",
  props: ["hr", "item"],
};
</script>

<style lang="scss" scoped>
.textCapitalize {
  text-transform: capitalize;
}
.blogBox {
  border: 1px solid #efefef;
  border-radius: 8px;
  margin-bottom: 20px;
  .blogImage {
    height: 315px;

    width: 100%;
    margin-bottom: -10px;
    background: #efefef;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0;
    }
  }
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 30px;
    margin-top: 10px;
  }
  p {
    height: 40px;
    overflow: hidden;
    font-size: 14px;
  }
  .blogFooter {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-top: 5px;
    width: 100%;

    li {
      margin-right: 15px;
      display: flex;
      color: #8c8c8c;
      font-size: 14px;
      align-items: center;
      @media only screen and (max-width: 991px) {
        margin-bottom: 4px;
      }
      .v-icon {
        margin-right: 8px;
        font-size: 18px;
        color: #000;
      }
    }
  }
}
.blogContent {
  padding: 20px;
}
@media only screen and (min-width:991px){
.hrBox {
  h3 {
    font-size: 22px;
    line-height: 26px;
  }
  display: flex;
  flex-wrap: wrap;
  height: 215px;
  align-items: center;
  .blogContent {
    height: 215px;
    width: 70%;
  }
  .blogImage {
    height: 215px;
    width: 30%;
    margin-bottom: 0;
    border: 1px solid #efefef;
    border-right: 0;
    img {
      object-fit: contain;
    }
  }
  .blogFooter {
    font-size: 14px;
    li {
      color: #8c8c8c;
      margin-right: 5px;
      .v-icon {
        font-size: 16px;
        margin-right: 10px;
        color: #000;
      }
    }
  }
}
}

a {
  color: #000;
}
</style>