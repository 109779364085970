<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12" v-if="!loading">
          <h1 class="systemTitle">Overview</h1>
          <ul class="statisticBox">
            <li v-for="(data, index) in allAnalytic.results" :key="index + 'analytic'">
              <span>Total {{ data.title }}</span>
              <strong>{{ data.value }}</strong>
            </li>
          </ul>
        </v-col>
        <v-col md="12" v-if="loading">
          <h1 class="systemTitle">Overview</h1>
          <ul class="statisticBox">
            <li v-for="n of 4" :key="n + 'analytic'">
              <span><LoadingSkeleton text="1" /></span>
              <span><LoadingSkeleton title="4" /></span>
            </li>
          </ul>
        </v-col>
        <v-col md="12">
          <h1 class="systemTitle">Latest Order</h1>
          <Order typeoforder="Pending" />
        </v-col>
        <v-col md="12">
          <h1 class="systemTitle">Latest Booking</h1>
          <Booking bookingType="Pending" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Order from "@/components/business/Order";
import Booking from "@/components/business/Booking";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  components: {
    Order,
    Booking,
    LoadingSkeleton
  },
  computed: {
    ...mapGetters(["allAnalytic"]),
  },
  methods: {
    ...mapActions(["getAnalytic"]),
  },
  async created() {
    await this.getAnalytic();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.statisticBox {
  display: flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin: 0 -10px;
  li {
    width: 100%;
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    box-shadow: 0 4px 5px #efefef;
    margin: 0 10px;
    border-radius: 8px;
    span {
      font-size: 20px;
    }
    strong {
      font-size: 40px;
    }
  }
}
</style>