<template>
  <section class="mobile-screen">
    <ul class="cursorIcons" v-if="images.length > 1">
      <li><v-icon @click="prev"> mdi-chevron-left </v-icon></li>
      <li><v-icon @click="next"> mdi-chevron-right </v-icon></li>
    </ul>
    <slick ref="slick" :options="slickOptions">
      <div class="image" v-for="(img, index) in images" :key="'as' + index">
        <img :src="img.imageWeb" class="imageBox" alt="" />
      </div>
    </slick>
  </section>
</template>

<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
export default {
  name: "Slider",
  components: { Slick },
  props: ["images"],
  data: () => ({
    slickOptions: {
      slidesToShow: 1,
      dots: false,
      arrows: false,
      fade: false,
      autoplay: true,
      autoplaySpeed: 2000,
      infinite: true,
    },
    media: [],
    index: 0,
    visible: false,
  }),
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}
.mobile-screen {
  position: relative;
  @media only screen and (max-width: 991px) {
    margin-bottom: -40px;
    img {
      height: auto;
      
    }
  }
}
.cursorIcons {
  display: flex;
  justify-content: space-between;
  width: 95%;
  list-style: none;
  padding-left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  right: 15px;
  left: 15px;
  li {
    .v-icon {
      padding: 10px;
      border-radius: 8px;
      background: #f4f4f4;
    }
  }
}
</style>
