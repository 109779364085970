<template>
  <section class="blogSlider">
    <ul class="cursorIcons">
      <li><v-icon @click="prev"> mdi-chevron-left </v-icon></li>
      <li><v-icon @click="next"> mdi-chevron-right </v-icon></li>
    </ul>
    <div class="slidingSection">
      <slick ref="slick" :options="slickOptions">
        <div class="px-1" v-for="(data, index) in items" :key="'as' + index">
          <ProductBox :item="data" />
        </div>
      </slick>
    </div>
  </section>
</template>

<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import ProductBox from "@/components/ProductBox";
export default {
  name: "ProductSlider",
  components: { Slick, ProductBox },
  props: ["images", "items"],
  data: () => ({
    slickOptions: {
      slidesToShow: 4,
      dots: false,
      arrows: false,
      fade: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    },
    media: [],
    index: 0,
    visible: false,
  }),
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.blogSlider {
  position: relative;
}

.cursorIcons {
  display: flex;
  list-style: none;
  padding-left: 0;
  position: absolute;
  top: -55px;
  right: 3px;
  li {
    margin-left: 5px;
    .v-icon {
      padding: 10px;
      background: #f4f4f4;
    }
  }
}
</style>
